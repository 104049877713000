import { useEffect, useRef } from 'react';
import { ResponseStandardFail } from './api';

// for exposing complex combined types
// eg:
// type ComplexType = SomeType & SomeType2 & SomeType3;
// const variableName: Prettify<ComplexType> = { ... };
// https://twitter.com/mattpocockuk/status/1653403198885904387
export type Prettify<T> = {
	[K in keyof T]: T[K];
} & {};

// create a mostly random guid like string
export const guid = () => {
	const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	return (S4() + S4() + '-' + S4() + '-4' + S4().substring(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
};

export const usePrevious = (value: any) => {
	const ref = useRef(value);

	useEffect(() => {
		ref.current = value; // assign the value of ref to the argument
	}, [value]); // this code will run when the value of 'value' changes

	return ref.current; // in the end, return the current ref value.
};

export const createErrorsObject = (response: ResponseStandardFail) => {
	if (!response.isError) return {};

	const errorsObj: { [key: string]: string[] } = {};
	if (response.validationErrors?.length) {
		for (const err of response.validationErrors) {
			const { name = '', reason = '' } = err;
			if (!errorsObj[name]) errorsObj[name] = [];
			errorsObj[name].push(reason);
		}
	}
	if (response.errors?.length) {
		for (const err of response.errors) {
			const { message = '' } = err;
			if (!errorsObj.null) errorsObj.null = [];
			errorsObj.null.push(message);
		}
	}

	// formik expects errors to be a single string
	// remap the values in the object so that the values are a single string rather than array of strings
	const updatedErrorsObj: { [key: string]: string } = {};
	Object.entries(errorsObj).map((entry) => {
		const [key, values] = entry;
		// keys coming from the back end are in PascalCase, but we need them in camelCase like the form so errors can be mapped correctly
		return (updatedErrorsObj[lowercaseFirstLetter(key)] = values.join('\n'));
	});

	return updatedErrorsObj;
};

export const lowercaseFirstLetter = (str: string) => {
	return str.charAt(0).toLowerCase() + str.slice(1);
};

export const loadExternalScript = (url: string, id: string, callback?: () => void) => {
	const isScriptExist = document.getElementById(id);

	if (isScriptExist) {
		if (callback) callback();
	} else {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = url;
		script.id = id;
		script.defer = true;
		if (callback)
			script.onload = () => {
				callback();
			};
		document.body.appendChild(script);
	}
};

// Google Recaptcha V3 Invisible
export const SITE_KEY = '6LfjZYwpAAAAACqK8m0wBzFwhYGUHw9fTlb1ulU6';
export const loadRecaptchaScript = () => {
	loadExternalScript(`https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, 'recaptcha-key');
};

export const shuffleArray = <T>(arr: T[]) => {
	// should guarantee that the result is nearly always different to the original
	let i = arr.length;
	let j = 0;
	let temp: T;

	// create a loop that subtracts every time it iterates through
	while (--i > 0) {
		j = Math.floor(Math.random() * (i + 1));
		temp = arr[j]; // create temporary position from the item of the random number
		arr[j] = arr[i]; // swap temp item with position of last item in array
		arr[i] = temp; // swap last item with position of temp item
	}

	return arr;
};

export const rectangularCollision = (rectangle1: DOMRect, rectangle2: DOMRect) => {
	return (
		rectangle1.right >= rectangle2.left &&
		rectangle1.left <= rectangle2.right &&
		rectangle1.top <= rectangle2.bottom &&
		rectangle1.bottom >= rectangle2.top
	);
};

export const debounce = <T extends (...args: any[]) => any>(fn: T, delay: number) => {
	let timerId: number;
	return function (this: ThisType<T>, ...args: Parameters<T>): void {
		clearTimeout(timerId);
		timerId = window.setTimeout(() => fn.apply(this, args), delay);
	} as T;
};

export const convertToKebabCase = (str: string) => str.replace(/\W/g, '-').toLowerCase();

// Remove duplication from an array of objects
export const removeDuplicates = (arr: any[], key: string): any[] => {
	return arr.reduce((unique, item) => {
		if (!unique.find((obj: any) => obj[key] === item[key])) {
			unique.push(item);
		}
		return unique;
	}, []);
};

// formatting price with a '$' and removing the commas
export const formatPrice = (price: number | undefined) =>
	price
		? new Intl.NumberFormat('en-GB', {
				style: 'currency',
				currency: 'AUD',
				currencyDisplay: 'narrowSymbol',
				useGrouping: false
		  }).format(price)
		: '';

export const getUserDomain = (user: 'student' | 'student-trial' | 'staff' | 'staff-trial' | 'buyer') => {
	// the domains returned by the domainMap set by Octopus may be split by ;
	// this code will sort that out
	const resolvedDomainMapItem = window.constants.domainsMap.find((domain) => domain.userType === user);
	const firstDomainInMapItem = resolvedDomainMapItem?.domains?.split(';')?.[0];
	return firstDomainInMapItem?.startsWith('http') ? firstDomainInMapItem : 'https://' + firstDomainInMapItem;
};
