import './assets/styles/style.scss';

import React, { useEffect, useState } from 'react';

// PACKAGES
import { Spinner, ToastContainer } from 'react-bootstrap';

// REDUX
import { Route, Routes, useLocation } from 'react-router-dom';
import { UserSlice, UserType } from './store/slice/User';
import { store, useAppDispatch, useAppSelector } from './store/slice';
import { SystemInfoSlice } from './store/slice/SystemInfo';

// API
import apibridge from './apibridge';

// PAGES
import Styleguide from './pages/Styleguide/Styleguide';
import Page404 from './pages/ErrorPages/Page404';

// COMPONENTS
import ToastInstance from './components/_Helpers/CustomToast';
import useStaffRoutes from './components/_Routes/StaffRoutes';
import useStudentRoutes from './components/_Routes/StudentRoutes';
import useBuyerRoutes from './components/_Routes/BuyerRoutes';
import ToastNag from './components/ToastNag/ToastNag';

// UTILS
import { guid, usePrevious } from './libs/utils';
import { useAudioPlayer } from './libs/customHooks';
import { checkUserSession } from './apibridge/checkUserSession';
import DelayedFadeIn from './components/_Helpers/DelayedFadeIn';

// TYPES
import { ToastMessageType, ToastMessagesSlice } from './store/slice/ToastMessages';
import { Policy } from './api/models/policy';

const App: React.FC = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { clearAudioQueue } = useAudioPlayer();

	const user: UserType = useAppSelector((state) => state.user);
	const toastMessages: ToastMessageType[] = useAppSelector((state) => state.toastMessages);

	const systemInfo = useAppSelector((state) => state.systemInfo);
	const isStaff = systemInfo.type === 'staff';
	const isStudent = systemInfo.type === 'student';
	const isBuyer = systemInfo.type === 'buyer';

	const staffRoutes = useStaffRoutes();
	const studentRoutes = useStudentRoutes();
	const buyerRoutes = useBuyerRoutes();

	const [isMounted, setIsMounted] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const previousUserState: UserType = usePrevious(user);

	// find out the type of user and if we're in 'trial-mode' based on the domain
	const getSystemInfo = async () => {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			const response = await apibridge.getSystemInfo();
			if (response && response.data && response.data.type) {
				dispatch(SystemInfoSlice.actions.update(response.data));
			}
		} else {
			dispatch(
				SystemInfoSlice.actions.update({
					type: window.constants.type,
					isTrial: window.constants.isTrial
				})
			);
		}
	};

	const authenticateUserFromToken = async () => {
		// on page first load or refresh - if localStorage has token - they are/were loggedIn
		const hasToken = !!localStorage.getItem('token');
		if (!hasToken) {
			setIsLoading(false);
			return;
		}

		// authenticate token before grabbing user info, since the permissions may have changed
		// refreshing the page should force a token refresh before grabbing their info

		await apibridge.refreshAuthenticationToken();

		const response = await apibridge.getUserInfo();
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				const results: UserType = {
					...response.data.result,
					forceUpdate: false,
					loggedIn: true
					// for debugging the user profile object
					// profiles: response.data.result.profiles?.map((profile) => {
					// 	return {
					// 		...profile,
					// 		staffAdded: false,
					// 		studentsAdded: false
					// 	};
					// }),
					// ftuxCompleted: false
				};
				dispatch(UserSlice.actions.update(results));
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Authentication error',
							description: err.reason || 'Please log in again'
						})
					);
					dispatch(UserSlice.actions.logOut());
				}
			} else {
				ToastMessagesSlice.actions.add({
					id: guid(),
					type: 'danger',
					heading: 'Authentication error',
					description: 'Please log in again'
				});
				dispatch(UserSlice.actions.logOut());
			}
		}

		setIsLoading(false);
	};

	const getInitialData = async () => {
		setIsLoading(true);
		await Promise.all([getSystemInfo(), authenticateUserFromToken()]);
		setIsLoading(false);
	};

	useEffect(() => {
		// page changed
		if (location.state?.preventScroll) {
			location.state.preventScroll = false;
		} else {
			const options = { top: 0, left: 0, behaviour: 'auto' };
			window.scrollTo !== undefined ? window.scrollTo(options) : window.scroll(options);
		}

		clearAudioQueue(); // clear audio on any URL switch

		if (checkUserSession() === false) {
			setIsLoading(true);
		}

		// push page view to tracking
		// GoogleAnalyticsHelper.sendPageView();
	}, [location]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// this will run on first mount and every time the user store object changes
		if (!isMounted) {
			setIsMounted(true);
			getInitialData();
		} else {
			// after component has mounted and user has updated
			setIsLoading(false);

			let userDataHasChanged = false;
			if (user.loggedIn && !user.id) {
				// user has just logged in but not retrieved complete user data
				userDataHasChanged = true;
			} else if (previousUserState.loggedIn && user.loggedIn && previousUserState.id) {
				// user is logged in and has all data

				if (user.forceUpdate) {
					userDataHasChanged = true;
				}
			}

			if (userDataHasChanged) {
				getInitialData();
			}
		}
	}, [user]); // eslint-disable-line react-hooks/exhaustive-deps

	return isLoading ? (
		<div className="d-flex align-items-center justify-content-center position-absolute w-100 h-100">
			<DelayedFadeIn>
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			</DelayedFadeIn>
		</div>
	) : (
		<>
			{toastMessages.length > 0 && (
				<ToastContainer className="m-3 p-3" position={'top-end'}>
					{toastMessages.map((toastMessage) => {
						return <ToastInstance key={toastMessage.id} toastMessage={toastMessage} />;
					})}
				</ToastContainer>
			)}

			{user.permissions?.includes(Policy.AccountMessages) && <ToastNag />}

			<Routes>
				{isStaff ? staffRoutes : isStudent ? studentRoutes : isBuyer ? buyerRoutes : null}
				<Route path="/styleguide" element={<Styleguide />} />
				<Route path="*" element={<Page404 />} />
			</Routes>
		</>
	);
};

export const pushMessageToStore = (message: ToastMessageType) => {
	store.dispatch({
		type: 'toastMessages/add',
		payload: message
	});
};

export default App;
